import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { SelectItem } from 'primeng/api/selectitem';
import { Select, SelectModule } from 'primeng/select';
import { AssetTitlePipe } from '../../pipes/asset-title.pipe';
import { AssetIconComponent } from '../asset-icon/asset-icon.component';
import { IconComponent } from '../icon/icon.component';
import { AssetRootItem } from './../../models';

@Component({
  selector: 'app-tree-selector',
  templateUrl: './tree-selector.component.html',
  styleUrls: ['./tree-selector.component.scss'],
  standalone: true,
  imports: [
    SelectModule,
    FormsModule,
    SharedModule,
    AssetIconComponent,
    IconComponent,
    AssetTitlePipe,
  ],
})
export class TreeSelectorComponent implements OnChanges {
  @Input()
  public activeTreeId?: string;

  @Input()
  public trees: AssetRootItem[] = [];

  @Input()
  public disabled = false;

  @Input()
  public disableNonServiceTrees = false;

  @Input()
  maxHeight = '300px';

  @Output()
  public treeSelected: EventEmitter<string> = new EventEmitter();

  @ViewChild('treeSelector')
  public treeSelector: Select;

  public treeModel: SelectItem<AssetRootItem>[] = [];
  public activeTree?: AssetRootItem;

  public constructor(private assetTitlePipe: AssetTitlePipe) {}

  public ngOnChanges() {
    this.updateUi();
  }

  public updateUi() {
    this.updateTreeModel();
    this.updateTreeSelection();
  }

  public selectTree(event: { originalEvent: Event; value: AssetRootItem }) {
    if (event.value.uuid === undefined) {
      return;
    }
    const treeUuid: string = event.value.uuid;
    this.treeSelected.emit(treeUuid);
  }

  private updateTreeModel() {
    this.treeModel = this.trees
      .map(tree => ({
        value: tree.proxy(),
        label: this.assetTitlePipe.transform(tree),
        disabled: this.disableNonServiceTrees && !tree.crm365,
      }))
      .sort((first, second) => first.label.localeCompare(second.label));
  }

  private updateTreeSelection() {
    // Set timeout callback is used to trigger change detection after a new tree is selected by @Input change
    setTimeout(() => {
      if (this.treeModel.length > 0) {
        if (this.activeTreeId) {
          const selectedTree = this.treeModel.find(tree => tree.value.uuid === this.activeTreeId);
          this.activeTree = selectedTree?.value;
          return;
        }
        this.activeTree = this.treeModel[0].value;
        return;
      }

      this.activeTree = undefined;
    }, 0);
  }
}
