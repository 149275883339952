import { Component, computed, input, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AssetRootItem } from '@assethub/shared/models';
import { AssetTitlePipe } from '@assethub/shared/pipes';
import { NavigationSlot, RecentAssetService, TreeService } from '@assethub/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
import { pathFromRoutingTemplate } from '@assethub/shared/utils';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, concat } from 'rxjs';
import { MenuComponent } from '../menu/menu/menu.component';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';
import { AssetIconComponent } from '../asset-icon/asset-icon.component';
import { IconComponent } from '../icon/icon.component';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'app-tree-selector-overview',
  templateUrl: './tree-selector-overview.component.html',
  styleUrls: ['./tree-selector-overview.component.scss'],
  standalone: true,
  imports: [
    MenuComponent,
    TranslateModule,
    ListboxModule,
    FormsModule,
    AssetIconComponent,
    IconComponent,
    AssetTitlePipe,
    RadioButtonModule,
  ],
})
export class TreeSelectorOverviewComponent {
  readonly assetId = input<string>();
  readonly treeId = computed(() => this.computeTreeId());
  readonly trees = toSignal(this.trackTrees(), { initialValue: [] });

  public treeModel: SelectItem<AssetRootItem>[] = [];
  public activeTree?: SelectItem<AssetRootItem>;
  public showOnlyServiceTrees = false;
  public currentAssetId = signal<string | undefined>(undefined);

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private treeService: TreeService,
    private assetTitlePipe: AssetTitlePipe,
    private recentAssetService: RecentAssetService,
  ) {
    this.update();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.update();
    });
  }

  private update() {
    this.setServiceTreeState();
    this.updateUi();

    const uuid = this.recentAssetService.getActiveAssetUuid(NavigationSlot.ASSET);
    const root = this.treeService.findNode(uuid!)?.root;
    this.currentAssetId.set(root?.uuid);
  }

  navigateToTree(selectedTree: any) {
    if (selectedTree.value.uuid === undefined) {
      return;
    }
    const treeRootId: string = selectedTree.value.uuid;
    const tree = this.treeService.getTreeByUuid(treeRootId);
    if (!tree) {
      throw new Error(`No tree with uuid = ${treeRootId} was found`);
    }
    // Go to most recently visited node of this tree (which defaults to root node)
    return this.router.navigate(
      pathFromRoutingTemplate(this.route.snapshot, {
        uuid: tree.selectedNode,
        rootUuid: treeRootId,
      }),
    );
  }

  private computeTreeId(): string | undefined {
    const assetId = this.currentAssetId();
    if (!assetId) {
      return;
    }
    const node = this.treeService.findNode(assetId);
    if (!node) {
      return;
    }
    return node.root.uuid;
  }

  private trackTrees(): Observable<AssetRootItem[]> {
    return concat(
      this.treeService.fetchTrees(),
      this.treeService.treeChanged.pipe(map(x => this.treeService.rootNodes)),
    );
  }

  public updateUi() {
    this.updateTreeModel();
    this.updateTreeSelection();
  }

  private updateTreeModel() {
    this.treeModel = this.trees()
      .map(tree => ({
        value: tree.proxy(),
        label: this.assetTitlePipe.transform(tree),
        disabled: this.showOnlyServiceTrees && !tree.crm365,
      }))
      .sort((first, second) => first.label.localeCompare(second.label));
  }

  private updateTreeSelection() {
    // Set timeout callback is used to trigger change detection after a new tree is selected by @Input change
    setTimeout(() => {
      if (this.treeModel.length > 0) {
        if (this.treeId()) {
          const selectedTree = this.treeModel.find(tree => tree.value.uuid === this.treeId());
          this.activeTree = selectedTree;
          return;
        }
        this.activeTree = this.treeModel[0];
        return;
      }

      this.activeTree = undefined;
    }, 0);
  }

  private setServiceTreeState() {
    const url = this.router.routerState.snapshot.url;
    this.showOnlyServiceTrees = url.includes('maintenance365');
  }
}
