<div class="ellipsis">
  <label>{{ label }}</label>
  @if (field) {
    <app-sort-icon
      [field]="field"
      [filter]="filter"
      [filterName]="filterName"
      [type]="type"
    ></app-sort-icon>
  }
</div>
