import { Pipe, PipeTransform, inject } from '@angular/core';
import { ENVIRONMENT } from '../models';

@Pipe({
  name: 'completeUrl',
  standalone: true,
})
export class CompleteUrlPipe implements PipeTransform {
  private sickUrl = inject(ENVIRONMENT).sickUrl;

  /*
   * If required, completes URLs provided by the SICK CRM
   */
  public transform(href: string, fallback = ''): string {
    if (!href) {
      return fallback;
    }

    if (href.startsWith('http://') || href.startsWith('https://') || href.startsWith('blob:')) {
      return href;
    }

    if (href.length > 1 && href.startsWith('/')) {
      href = href.substring(1);
    }

    return this.sickUrl + href;
  }
}
