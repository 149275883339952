@if (assetId()) {
  <div class="container">
    @if (breadcrumbs().length > 0) {
      <div class="breadcrumb">
        <app-breadcrumb
          [items]="breadcrumbs()"
          (itemSelected)="navigateToAsset($event)"
        ></app-breadcrumb>
      </div>
    }
    <div class="toolbar">
      <ng-content select="[slot=toolbar]"></ng-content>
    </div>
  </div>
}
