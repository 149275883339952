<app-toast></app-toast>
<app-asset-deleted-toast></app-asset-deleted-toast>
<app-auth-notification></app-auth-notification>

@if (!startApp) {
  <div id="animation-container">
    <div class="animation">
      <div id="sick-bootstrap-spinner" class="spinner" data-fadeout-class="spinner fadeout">
        <div class="circle bounce"></div>
        <div class="circle bounce delayed"></div>
      </div>

      <img
        id="sick-bootstrap-logo"
        alt="Logo of SICK AG"
        class="sick-logo"
        data-fadeout-class="sick-logo fadeout"
        src="assets/images/sick-logo.svg"
      />
    </div>

    <div id="bootstrap-message">{{ 'loading.title' | translate }}</div>
  </div>
} @else {
  <ng-container>
    <app-sick-header></app-sick-header>
    @if (busy) {
      <app-navigation-spinner [externalTrigger]="true"></app-navigation-spinner>
    }
    <p-blockUI #blockUiComponent [blocked]="uiBlocked"></p-blockUI>

    <div class="content">
      @if (showNavigation) {
        <app-drawer-navigation [navItems]="navItems"></app-drawer-navigation>
      }
      <div class="divider">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>
}
