<div>
  <p-select
    #treeSelector
    [scrollHeight]="maxHeight"
    [overlayOptions]="{
      showTransitionOptions: '0ms',
      hideTransitionOptions: '0ms',
    }"
    [options]="treeModel"
    [(ngModel)]="activeTree"
    (onChange)="selectTree($event)"
    dataKey="uuid"
  >
    <ng-template let-tree pTemplate="selectedItem">
      <app-asset-icon [asset]="tree.value"></app-asset-icon>
      <p>{{ tree.value | assetTitle }}</p>
      @if (tree.value.crm365) {
        <app-icon size="22" shape="build"></app-icon>
      }
    </ng-template>
    <ng-template let-tree pTemplate="item">
      @if (!disableNonServiceTrees || tree.value.crm365) {
        <app-asset-icon [asset]="tree.value"></app-asset-icon>
        <p>{{ tree.value | assetTitle }}</p>
        @if (tree.value.crm365) {
          <app-icon size="22" shape="build"></app-icon>
        }
      }
      @if (!tree.value.crm365 && disableNonServiceTrees) {
        <ng-container class="disableClicks">
          <app-asset-icon [disabled]="true" [asset]="tree.value"></app-asset-icon>
          <p class="disabled">{{ tree.value | assetTitle }}</p>
        </ng-container>
      }
    </ng-template>
  </p-select>
</div>
