import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, of, throwError } from 'rxjs';
import { ENVIRONMENT } from '../models';
import {
  HashValue,
  LifecycleState,
  SoftwarePackage,
  SoftwarePackagesResponse,
  SoftwareResponse,
} from '../models/software-package';

interface SoftwarePackageRaw {
  id: string;
  name: string;
  version: string;
  versionLabel?: string;
  size: number;
  owner: string;
  createdAt: string; // The format is the date-time notation as defined by RCF3339, section 5.6
  hashes: HashValue[];
  fileType: string;
  fileFormat: string;
  lifecycleState: LifecycleState;
}

interface SoftwarePackagesResponseRaw {
  data: SoftwarePackageRaw[];
}

const EMPTY_PACKAGE: SoftwareResponse = {};
const EMPTY_PACKAGES: SoftwarePackagesResponse = { data: [] };

@Injectable({
  providedIn: 'root',
})
export class SoftwareDeploymentService {
  private sdmUrl = inject(ENVIRONMENT).sdmUrl;

  constructor(private httpClient: HttpClient) {}

  getDeviceFirmware(asset: {
    serialNumber: string;
    partNumber: string;
  }): Observable<SoftwareResponse> {
    if (!this.sdmUrl) {
      return of(EMPTY_PACKAGE);
    }
    if (!asset.serialNumber || !asset.partNumber) {
      return of(EMPTY_PACKAGE);
    }

    // endpoint to use is currently unclear and therefore commented out:
    //
    // const pid = this.getEncodedPid(asset);
    // return this.httpClient.get<FirmwareResponseRaw>(`${this.sdmUrl}/...???...`);

    return throwError(() => new Error('Query device firmware is not implemented yet'));
  }

  getProductPackages(asset: {
    partNumber: string;
    serialNumber: string;
  }): Observable<SoftwarePackagesResponse> {
    if (!this.sdmUrl) {
      return of(EMPTY_PACKAGES);
    }
    if (!asset.partNumber || !asset.serialNumber) {
      return of(EMPTY_PACKAGES);
    }

    const pid = this.getEncodedPid(asset);
    return this.httpClient
      .get<SoftwarePackagesResponseRaw>(`${this.sdmUrl}/products/${pid}/packages`)
      .pipe(
        map(res => ({
          data: res.data.map(element => this.convertSoftwarePackageRaw(element)),
        })),
      );
  }

  private getEncodedPid(asset: { serialNumber: string; partNumber: string }) {
    return encodeURIComponent(`pid.sick.com/${asset.partNumber}/${asset.serialNumber}`);
  }

  private convertSoftwarePackageRaw(raw: SoftwarePackageRaw): SoftwarePackage {
    return {
      ...raw,
      createdAt: new Date(raw.createdAt),
    };
  }
}
