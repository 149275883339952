import { HttpClient } from '@angular/common/http';
import { Component, Input, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { Logger } from '@assethub/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import saveAs from 'file-saver';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

export interface File {
  id: string;
  title: string;
  type: string;
  downloading: boolean;
  assetUuid: string;
}

@Component({
  selector: 'app-download-service-file',
  templateUrl: './download-service-file.component.html',
  styleUrls: ['./download-service-file.component.scss'],
  standalone: true,
  imports: [ProgressSpinnerModule, ButtonModule, TranslateModule],
})
export class DownloadServiceFileComponent {
  @Input() file: File;

  private logger = new Logger(this.constructor.name);
  private sm365Url = inject(ENVIRONMENT).sm365Url;
  private sm365DocumentProxyUrl = inject(ENVIRONMENT).sm365DocumentProxyUrl;

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
  ) {}

  onDownload() {
    this.file.downloading = true;
    this.httpClient
      .get(
        `${this.sm365DocumentProxyUrl || this.sm365Url}/tree/${
          this.file.assetUuid
        }/document/${this.file.id}`,
        {
          responseType: 'blob',
        },
      )
      .subscribe({
        next: res => {
          this.file.downloading = false;
          saveAs(res, `${this.file.title}.${this.file.type}`);
        },
        error: err => {
          this.file.downloading = false;
          this.logger.error('failed to download service file:', err);
          this.messageService.add({
            severity: 'error',
            summary:
              'service-module-365.list-of-work-order-documents.message.toasts.download-failed',
            life: 10000,
          });
        },
      });
  }
}
