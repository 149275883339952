<!-- Core header display -->
<div class="header">
  <img src="assets/images/sick-logo-small.svg" class="logo" alt="Sick AG" />
  <div class="application_title">
    {{ 'toolbar.assetHub' | translate }}
  </div>
  <app-global-search class="search"></app-global-search>
  @if (showFeedbackButton) {
    <button
      pButton
      type="button"
      class=""
      icon="pi pi-feedback"
      title="{{ 'header.feedback' | translate }}"
      (click)="onShowFeedbackModal()"
    ></button>
  }
  <app-menu
    icon="custom_world"
    iconSize="24"
    offset="9"
    title="{{ 'toolbar.language' | translate }}"
  >
    @for (lang of languages; track lang) {
      <app-menu-item
        [label]="lang.label"
        [disabled]="lang.disabled || false"
        [checked]="lang.code === language"
        (selected)="onLanguageChange(lang.code)"
      ></app-menu-item>
    }
  </app-menu>

  <app-tree-selector-overview></app-tree-selector-overview>

  @if (showAppsMenuInHeader) {
    <app-menu icon="apps" iconSize="24" offset="9" title="{{ 'header.apps' | translate }}">
      @for (app of apps; track $index) {
        <app-menu-item
          [label]="app.label"
          [icon]="app.icon"
          (selected)="onOpenInNewWindow(app.url)"
        >
        </app-menu-item>
      }
    </app-menu>
  }
  <app-menu icon="info" iconSize="24" offset="9" title="{{ 'header.legal-documents' | translate }}">
    @for (link of legalLinks; track $index) {
      <app-menu-item
        [label]="link.label"
        (selected)="onOpenInNewWindow(link.url)"
        [disabled]="link.disabled ?? false"
      >
      </app-menu-item>
    }
  </app-menu>
  <app-menu icon="account_circle" iconSize="24" offset="9" title="{{ 'header.user' | translate }}">
    <app-menu-item
      [label]="'toolbar.sickProfile' | translate"
      (selected)="onShowProfile()"
    ></app-menu-item>
    <app-menu-item
      [label]="'toolbar.changePassword' | translate"
      (selected)="onChangePassword()"
    ></app-menu-item>
    <app-menu-item [label]="'toolbar.logout' | translate" (selected)="onLogout()"></app-menu-item>
  </app-menu>
  <app-menu iconSize="24" offset="9" title="{{ 'header.more' | translate }}">
    <app-menu-item [label]="'toolbar.releaseNotes' | translate" (selected)="onReleaseNotes()">
    </app-menu-item>
    <app-menu-item
      [label]="'toolbar.licenses' | translate"
      (selected)="onShowLicenses()"
    ></app-menu-item>
    <app-menu-item [label]="'toolbar.trashBin' | translate" (selected)="onTrashBin()">
    </app-menu-item>
    @if (showGuiCheckPage) {
      <app-menu-item
        [label]="'toolbar.guiCheck' | translate"
        (selected)="onGuiCheck()"
      ></app-menu-item>
    }
  </app-menu>
</div>
