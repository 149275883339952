@if (currentAssetId() && treeModel.length > 1) {
  <app-menu icon="switch_account">
    @if (activeTree) {
      <div class="treeSelect">
        <app-asset-icon
          [asset]="{
            type: activeTree.value.type,
            profilePicture: activeTree.value.profilePicture,
            productPictureUrl: activeTree.value.productPictureUrl,
            icon: activeTree.value.icon,
          }"
        ></app-asset-icon>
        <p>{{ activeTree.value | assetTitle }}</p>
        @if (activeTree.value.crm365) {
          <app-icon size="22" shape="build"></app-icon>
        }
      </div>
      <hr />
    }

    <div class="treeSelect">
      <p-listbox
        [options]="treeModel"
        [(ngModel)]="activeTree"
        optionLabel="label"
        optionDisabled="disabled"
        scrollHeight="400px"
        (onClick)="navigateToTree($event.option)"
      >
        <ng-template let-tree pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <app-asset-icon [asset]="tree.value"></app-asset-icon>
            <p>{{ tree.value | assetTitle }}</p>
            @if (tree.value.crm365) {
              <app-icon size="22" shape="build"></app-icon>
            }
          </div>
        </ng-template>
        <ng-template let-tree pTemplate="item">
          <div class="flex align-items-center gap-2">
            <app-asset-icon [asset]="tree.value"></app-asset-icon>
            <p>{{ tree.value | assetTitle }}</p>
            @if (tree.value.crm365) {
              <app-icon size="22" shape="build"></app-icon>
            }
          </div> </ng-template
      ></p-listbox>
    </div>
  </app-menu>
}
