import { Component, Input, OnInit } from '@angular/core';
import { DrawerMenuItem } from '@assethub/shared/models';
import { UserConfigurationService } from '@assethub/shared/services';
import { take } from 'rxjs';
import { DrawerNavigationItemComponent } from '../drawer-navigation-item/drawer-navigation-item.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-drawer-navigation',
  templateUrl: './drawer-navigation.component.html',
  styleUrls: ['./drawer-navigation.component.scss'],
  standalone: true,
  imports: [DrawerNavigationItemComponent, IconComponent],
})
export class DrawerNavigationComponent implements OnInit {
  @Input() navItems: DrawerMenuItem[];
  expanded = false;
  isExpandStateLocked = false;

  constructor(private userConfigService: UserConfigurationService) {}

  ngOnInit(): void {
    this.userConfigService
      .config()
      // The first event will include the default config (expanded = false).
      // The second event will include the config loaded from locale storage.
      .pipe(take(2))
      .subscribe(config => {
        this.isExpandStateLocked = config.navDrawerExpanded;
        this.expanded = config.navDrawerExpanded;
      });
  }

  toggleExpandState() {
    this.expanded = !this.expanded;
  }

  persistExpandMode() {
    this.isExpandStateLocked = !this.isExpandStateLocked;
    this.userConfigService.setNavDrawerExpanded(this.isExpandStateLocked);
  }
}
