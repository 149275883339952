<app-navigation-spinner></app-navigation-spinner>

<h3>{{ 'open-with.product-id.title' | translate }}</h3>

@if (showLoadingIndicator) {
  <app-callout
    ><div class="label">
      <p-progressSpinner strokeWidth="4"></p-progressSpinner>
      <div>{{ 'open-with.product-id.loading' | translate }}</div>
    </div>
  </app-callout>
}

@if (showCreateHint) {
  <app-callout
    ><span>{{ 'open-with.product-id.next-steps.title' | translate }}</span>
    <ol>
      <li>
        <a [routerLink]="['/asset']">{{ 'open-with.product-id.next-steps.list.0' | translate }}</a>
      </li>
      <li>{{ 'open-with.product-id.next-steps.list.1' | translate }}</li>
      <li>{{ 'open-with.product-id.next-steps.list.2' | translate }}</li>
      <li>{{ 'open-with.product-id.next-steps.list.3' | translate }}</li>
      <li>{{ 'open-with.product-id.next-steps.list.4' | translate }}</li>
    </ol>
  </app-callout>
}

@if (matchingAssets.length > 0) {
  <app-callout severity="success">{{
    'open-with.product-id.found-assets' | translate
  }}</app-callout>

  <div>
    @for (item of matchingAssets; track item.uuid) {
      <ng-container *ngTemplateOutlet="searchResult; context: { item: item }"></ng-container>
    }
  </div>
}

<ng-template #searchResult let-searchResult="item">
  <div class="row" (click)="openAsset(searchResult.uuid)">
    @if (searchResult.productPictureUrl) {
      <div class="productPicture">
        <img
          class="searchResultPicture"
          [src]="searchResult | assetImageUrl | async"
          width="48"
          height="48"
        />
      </div>
    }
    <div class="info">
      <div class="primary">
        <a [routerLink]="['/asset', searchResult.uuid]">
          <div>
            {{ searchResult.customName || searchResult.productName }}
          </div>
        </a>
      </div>
      <div class="secondary">
        @if (searchResult.customName) {
          <span>
            {{ 'open-with.product-id.asset-fields.productName' | translate }}:
            {{ searchResult.productName }} |
          </span>
        }
        <span>
          {{ 'open-with.product-id.asset-fields.partNumber' | translate }}:
          {{ searchResult.partNumber }} |
        </span>
        <span>
          {{ 'open-with.product-id.asset-fields.serialNumber' | translate }}:
          {{ searchResult.serialNumber }} |
        </span>
        <span class="tree">
          {{ 'open-with.product-id.asset-fields.tree' | translate }}:
          {{ searchResult.rootName }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
