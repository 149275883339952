<div class="drawer-navigation-item">
  <div
    class="content-pane"
    [class.active]="isActive"
    [class.minimized]="!maximizeView"
    [class.sub-menu-item]="depth > 0"
    [ngStyle]="{ height: itemHeight }"
    (click)="onNavItemClicked()"
  >
    @if (maximizeView) {
      @if (navItem.icon) {
        <app-icon class="nav-item-icon" [shape]="navItem.icon"></app-icon>
      }
      <span class="nav-item-label" [class.no-icon]="!navItem.icon">{{
        navItem.label | translate
      }}</span>
      @if (hasChildren) {
        <app-icon
          class="expand-option"
          shape="{{ expandChildren ? 'expand_less' : 'expand_more' }}"
        ></app-icon>
      }
    } @else {
      @if (!hasChildren) {
        @if (navItem.icon) {
          <app-icon class="nav-item-icon" [shape]="navItem.icon" (click)="navigate()"></app-icon>
        }
      } @else {
        @if (navItem.icon) {
          <app-menu
            #appMenuHost
            class="nav-item-icon"
            direction="rd"
            [icon]="navItem.icon"
            iconSize="24px"
            [backgroundColor]="menuBackgroundColor"
            (click)="$event.stopPropagation(); $event.preventDefault()"
            anchor="component"
            [openOnHover]="true"
            offset="18"
          >
            <div class="floating-menu">
              <span class="floating-menu-header">{{ navItem.label | translate }}</span>
              <ng-content
                *ngTemplateOutlet="childMenus; context: { appMenuHost: appMenuHost }"
              ></ng-content>
            </div>
          </app-menu>
        }
      }
    }
  </div>

  @if (maximizeView && hasChildren && expandChildren) {
    <div>
      <ng-content *ngTemplateOutlet="childMenus"></ng-content>
    </div>
  }
</div>

<ng-template #childMenus let-appMenuHost="appMenuHost">
  @for (child of navItem.children; track child.label) {
    <app-drawer-navigation-item
      [drawerExpanded]="drawerExpanded"
      [navItem]="child"
      [depth]="depth + 1"
      [currentMenuHost]="appMenuHost ?? currentMenuHost"
    ></app-drawer-navigation-item>
  }
</ng-template>
