import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ReleaseNotesService, TranslatedReleaseNote } from './../../services/release-notes.service';

@UntilDestroy()
@Component({
  selector: 'app-release-note',
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.scss'],
  standalone: true,
})
export class ReleaseNoteComponent implements OnInit {
  @Input()
  public releaseNote: TranslatedReleaseNote;
  public releaseNoteHtml?: SafeHtml;

  constructor(
    private releaseNotesService: ReleaseNotesService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.download();
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => this.download());
  }

  private download() {
    this.releaseNotesService
      .downloadReleaseNoteHtml(this.releaseNote.date)
      .pipe(untilDestroyed(this))
      .subscribe(html => (this.releaseNoteHtml = html));
  }
}
