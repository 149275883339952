<div class="navbar-scroll" data-testid="drawer-navigation">
  <div class="drawer-navigation" [class.expanded]="expanded">
    <div class="nav-items">
      @for (navItem of navItems; track navItem.label) {
        <app-drawer-navigation-item
          [navItem]="navItem"
          [drawerExpanded]="expanded"
        ></app-drawer-navigation-item>
      }
    </div>
    <div class="divider divider-margin-top"></div>
    <div class="action-bar">
      @if (expanded) {
        <app-icon
          class="drawer-navigation-button"
          shape="{{ !isExpandStateLocked ? 'custom_pin_outline' : 'custom_pin_off_outline' }}"
          (click)="persistExpandMode()"
        ></app-icon>
      }
      <app-icon
        class="drawer-navigation-button"
        shape="pi {{ !expanded ? 'pi-double-arrow-right' : 'pi-double-arrow-left' }}"
        (click)="toggleExpandState()"
      ></app-icon>
    </div>
    <div class="divider"></div>
  </div>
</div>
